// @flow

import React from 'react'
import classNames from 'classnames'
import Scrollspy from 'react-scrollspy'
import Headroom from 'react-headroom'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container } from '../../../basic/Grid'
import baseStyles from './ScrollSpy.scss'

type Props = {
  data?: any,
  ...StyleProps
}

const ScrollSpy = ({
  data,
  styles
}: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <Headroom disableInlineStyles className={styles.navScrollContainer}>
        <Container className={styles.navContainer} fluid>
          <Scrollspy
            items={data && data.length > 0 && data.map(item => item.id)}
            currentClassName={styles.isCurrent}
            className={styles.nav}
            offset={-150}
          >
            {data && data.length > 0 && data.map((item, idx) => (
              <li className={styles.navItem} key={idx}>
                <a className={styles.navItemLink} href={'#' + item.id}>
                  {item.icon && <span className={classNames(styles.itemIcon, item.icon)}/>}
                  <span>{formatMessage(item.title)}</span>
                </a>
              </li>
            ))}
          </Scrollspy>
        </Container>
      </Headroom>
    </div>
  )
}
export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ScrollSpy)
