// @flow

import React, { useCallback } from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import RelatedCS from '../CaseStudyModern/RelatedCS'
import FloatingCTA from '../basic/FloatingCTA'
import Section from './Section'
import ScrollSpy from './shared/ScrollSpy'

import baseStyles from './CommonLanding.scss'

type Props = {
  ...StyleProps,
  data: Array<Object>
}

const CommonLanding = ({ styles, data }: Props) => {
  const renderItem = useCallback(
    (item, idx) => {
      switch (item.type) {
        case 'Section':
          return (
            <Section
              layoutConf={item.layoutConf}
              sectionTitle={item.sectionTitle}
              sectionIcon={item.sectionIcon}
              sectionIconColour={item.sectionIconColour}
              title={item.title}
              text={item.text}
              list={item.list}
              buttonLabel={item.buttonLabel}
              buttonLink={item.buttonLink}
              gallery={item.gallery}
              quotes={item.quotes}
              faq={item.faq}
              tiles={item.tiles}
              offerTiles={item.offerTiles}
              persons={item.persons}
              imageRow={item.imageRow}
              galleryAlignment={item.galleryAlignment}
              modeSettings={item.modeSettings}
              id={item.id}
              key={idx}
            />
          )
        case 'RelatedCS':
          return <RelatedCS
            title={item.title}
            items={item.relatedCases}
            modeSettings={item.modeSettings}
            id={item.id}
            key={idx}
          />
        case 'FloatingCTA':
          return <FloatingCTA img={item.img} modeSettings={item.modeSettings} key={idx} />
        case 'ScrollSpy':
          return <ScrollSpy data={item.data} key={idx} />
        default:
          console.warn('There is no teaser type ' + item.type)
      }
    },
    [data]
  )

  return (
    <div className={styles.root}>
      <ParallaxProvider>{data.map(renderItem)}</ParallaxProvider>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CommonLanding)
